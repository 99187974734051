import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/vultr-2-runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const ButtonExternal = makeShortcode("ButtonExternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "château-de-morey-charme--prestige-de-france"
    }}><strong parentName="h1">{`Château de Morey Charme & Prestige de France`}</strong></h1>
    <h2 {...{
      "id": "een-geschiedenis-en-een-toekomst"
    }}>{`Een geschiedenis en een toekomst`}</h2>
    <h3 {...{
      "id": "snelle-presentatie"
    }}>{`Snelle presentatie`}</h3>
    <p><undefined parentName="p">{`
        `}<div {...{
          "className": "embedVideo-container"
        }}>{`
            `}<iframe parentName="div" {...{
            "title": "",
            "width": "100%",
            "height": 400,
            "src": "https://www.youtube-nocookie.com/embed/h0Wnp2wRXgw?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{`
        `}</div></undefined></p>
    <p>{`Het Château de Morey, gelegen in Lotharingen, verwelkomt u sinds 1998 binnen deze muren.`}</p>
    <p>{`Tussen Nancy en Metz kunt u zich ontspannen in een van de 5 ruime gastenkamers, vergezeld van het bucolische park en de activiteiten (midgetgolf, zwembad, SPA, ruitersport, tafelvoetbal, biljart, jeu de boules).`}</p>
    <p>{`Een uitzonderlijke tussenstop, het 16e eeuwse kasteel, de voormalige residentie van de hertogen van Lotharingen, gemakkelijk bereikbaar (op 5 km van de A31 op de D44a). Geïsoleerd van de stad en toch op slechts 20 minuten van Nancy en 10 minuten van verschillende lokale restaurants, genieten van een fabelachtig moment van zoetigheid in een zekere rust.`}</p>
    <p>{`Het Château is preciezer gelegen in de gemeente Belleau, op een hoogte van 305 meter, die een adembenemend uitzicht biedt op de Natagne vallei die de charme van Lotharingen verspreidt.`}</p>
    <p>{`U kunt ook uw recepties organiseren in een residentie die sinds een twintigtal jaar bruiloften, seminaries en andere evenementen organiseert.`}</p>
    <p>{`Vandaag de dag is het ontvangen van u binnen deze muren, zodat u een moment van verwondering kunt doorbrengen, de prioriteit van het Château de Morey. Een plek die rust en charme combineert en een verblijf creëert dat u in staat stelt om uw batterijen weer op te laden.`}</p>
    <ButtonInternal to="/nl/bed-and-breakfast/" mdxType="ButtonInternal">Ontdek onze gastenkamers</ButtonInternal>
    <ButtonInternal to="/nl/halls/" mdxType="ButtonInternal">Ontdek onze kamerverhuur</ButtonInternal>
    <h2 {...{
      "id": "its-history"
    }}>{`Its history`}</h2>
    <p><img parentName="p" {...{
        "src": "../../content/assets/chateau_de_morey.jpg",
        "alt": "Château de Morey"
      }}></img><strong parentName="p">{`Château de Morey`}</strong></p>
    <p>{`In het 12e eeuwse Lotharingen zou een kasteel zijn gebouwd op de ruïnes van een tempeliersklooster. Het zou de residentie van de Sire of Morey Jean de Toulon zijn geweest in de tijd dat het kasteel, de boerderij en de Romaanse kerk van de 12e eeuw tot het eigendom behoorden. Het was toen de familie Joly die de drie landgoederen scheidde en de schrijver Ducrocq die ze opvolgde.`}</p>
    <p>{`Het kasteel werd in 1985, na 8 jaar verlaten te zijn, gerestaureerd door de familie Karst, die vandaag de dag de commerciële activiteiten van het kasteel beheert.`}</p>
    <p>{`Ontdek in het bos boven het kasteel de overblijfselen van de geschiedenis. Te voet, op de fiets of te paard, wandel over de bospaden om oude oorlogsloopgraven, geheime galerijen en meer te zien…`}</p>
    <p>{`De geschiedenis van het Château de Morey strekt zich uit van heinde en verre. En ja, Lorraine is een regio met een zwaar verleden. Het heeft vele oorlogen, bezettingen en mysterieuze verhalen gekend. Van de middeleeuwen tot nu, door vele tijdperken heen, kunt u de geheimen van deze regio ontdekken.`}</p>
    <p>{`In het hart van de Meurthe et Moezel heeft de burcht de neiging om de vruchten van zijn geschiedenis te blijven dragen, u te begeleiden in uw intieme momenten, uw bruiloften te vieren, uw avonden te begeleiden… Een uitgestrekte tuin die wordt ondersteund door een muur van meer dan 5 meter hoog. Kastanjebomen, Libanese ceders, lindebomen, paarse beuken, eiken, esdoorns en een eendenvijver creëren een unieke ruimte op Château de Morey.`}</p>
    <ButtonInternal to="/nl/newsletter/" mdxType="ButtonInternal">Word lid van de nieuwsbrief</ButtonInternal>
    <h2 {...{
      "id": "zijn-huidige-leven"
    }}>{`Zijn huidige leven`}</h2>
    <p><img parentName="p" {...{
        "src": "../../content/assets/2020/04/tourelle2.jpeg",
        "alt": "Château de Morey"
      }}></img><strong parentName="p">{`Château de Morey`}</strong></p>
    <p>{`Het Château de Morey verwelkomt u vandaag nog steeds in zijn gebouwen en doet er alles aan om u een onvergetelijke tijd te bezorgen. Van de receptie tot aan de diensten, beleef een authentiek en spectaculair verblijf binnen de stenen muren van de residentie.`}</p>
    <p>{`Het domein is net zo goed een plek om uit te rusten als dat het kan worden omgevormd tot een feestelijke plek. Naast de receptie van uw evenementen, organiseren wij regelmatig avonden of brunches voor het plezier van het delen van goede tijden met u.`}</p>
    <p>{`Het Château wordt steeds verbeterd en is van plan zijn dak te herdoen. Om dit mogelijk te maken is er een kitty gecreëerd.`}</p>
    <ButtonExternal href="https://www.leetchi.com/c/projets-de-chateau-de-morey-22970336" mdxType="ButtonExternal">Neem deel aan</ButtonExternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      